@charset "UTF-8";

/* ------------------------------- */
//
// 最新の事例紹介
//
/* ------------------------------- */
.common_inc.inc_case_solutions {
	position: relative;
	margin-bottom: 110px;
	@include mq(sp) {
		margin-bottom: 23vw;
	}
	&:before {
		content: "";
		position: absolute;
		right: 0;
		top: 87px;
		height: calc(100% - 55px);
		width: calc(50% + 320px);
		background: url(/common/img/inc/bg_jirei.png) top left / cover no-repeat;
		z-index: -1;

		@include mq(sp) {
			background: url(/common/img/inc/bg_jirei_sp.png) top left / cover no-repeat;
			width: pcnt(660, 750);
			top: 18vw;
		}
	}
}
.case_intro {

	.contents {
		width: auto;
		max-width:1040px;

		@include mq(sp) {
			margin: 0;
			width: 100%;
		}
	}
	ul {
		&.inc_case_ul {

			margin: auto;
			display: flex;
			justify-content: space-between;
			flex-wrap: wrap;
			@include mq(md) {
				width: 100%;
			}
	
			@include mq(sp) {
				flex-direction: column;
			}
	
			li {
				width: calc((320% / 1040) * 100);
	
				@include mq(sp) {
					width: 100%;
					border-top: solid 1px #dddddd;
					padding: spvw(50) 0 0;
					margin: spvw(50) 0 0;
	
					&:first-of-type {
						border-top: none;
						margin: 0;
						padding: 0;
					}
					// &:last-of-type {
					// 	border-bottom: none;
					// }
				}
				> a {
					color: $defcolor;
					line-height: 1.7;
					dl {
						@include mq(sp) {
							display: flex;
							justify-content: space-between;
						}
						dt {
							@include mq(sp) {
								width: pcnt(280, 690);
							}
							figure {
								margin-bottom: 20px;
								display: block;
								width: 320px;
								height: 230px;
								position: relative;
								overflow: hidden;
								@include mq(md) {
									width: 100%;
									height: 20.6vw;
								}
								@include mq(sp) {
									overflow: unset;
									width: auto;
									height: auto;
								}
								img {
									display: block;
									position: absolute;
									transform: scale(1);
									transition: transform .3s;
		
								}
							}
						}
	
						dd {
							@include mq(sp) {
								width: pcnt(380, 690);
							}
						}
					}
					.inctxt_case {
						&_ttl {
							font-size: 16px;
							margin-bottom: 0.4em;
							font-weight: bold;
							letter-spacing: -.02em;
							@include mq(sp) {
								font-size: spvw(28);
							}
						}
		
						&_subTtl {
							font-size: 12px;
							color: #747377;
							margin-bottom: .8em;
		
							@include mq(sp) {
								margin-bottom:spvw(25);
								font-size: spvw(24);
							}
						}
		
						&_txt {
							margin-bottom: .85em;
							font-size: 14px;
		
							@include mq(sp) {
								margin-bottom: spvw(25);
								font-size:spvw(28);
							}
						}
		
						&_blank {
							margin-bottom: .45em;
							font-size: 12px;
							position: relative;
							display: inline-block;
							&:after {
								content: "";
								position: absolute;
								width: 1.1em;
								height: 1.4em;
								right: -1.3em;
								bottom: 0.1em;
								display: inline-block;
								background: transparent url(/common/img/ico/ico_blank.svg) right top / contain no-repeat;
							}
		
							@include mq(sp) {
								margin-bottom: spvw(25);
								font-size:spvw(24);
							}
						}
		
						&_lnk {
							margin-bottom: 1.45em;
							font-size: 14px;
		
							@include mq(sp) {
								margin-bottom: spvw(25);
								font-size:spvw(28);
							}
						}
		
						&_ico {
							display: inline-block;
							font-size: 12px;
							border-radius: 1em;
							border: solid 1px #c8b9f9;
							color: #5330c1;
							background-color: #FFFFFF;
							padding: 0 0.8em;
							letter-spacing: -.05em;
							margin-bottom: .4em;
							margin-right: .6em;
							@include mq(sp) {
								font-size:spvw(20);
							}
						}
					}
					&:hover {
						text-decoration: none;
						dl {
							dt {
								figure {
									img {
										transform: scale(1.05);
										@include mq(sp) {
											transform: scale(1);
										}
									}
								}
							}
						}
						p.inctxt_case_ttl {
							// color: $purple;
						}
					}
					&[target="_blank"] {
						> dl {
							> dd {
								p {
									&.inctxt_case_lnk {
										position: relative;
										display: inline-block;
										&:after {
											content: "";
											position: absolute;
											width: 1.3em;
											height: 1.6em;
											right: -1.5em;
											bottom: -0.05em;
											display: inline-block;
											background: transparent url(/common/img/ico/ico_blank.svg) right top / contain no-repeat;
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
}


body.ie {
	.case_intro {
		ul {
			&.inc_case_ul {
				li {
					> a {
						.inctxt_case {
							&_blank {
								&:after {
									width: 1.2em;
									height: 1.2em;
									bottom: 0.4em;
									background: transparent url(/common/img/ico/ico_blank.png) center center / contain no-repeat;
								}
							}
						}
						&[target="_blank"] {
							> dl {
								> dd {
									p {
										&.inctxt_case_lnk {
											&:after {
												width: 1.2em;
												height: 1.2em;
												bottom: 0.4em;
												background: transparent url(/common/img/ico/ico_blank.png) center center / contain no-repeat;
											}
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
}

