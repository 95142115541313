@charset "UTF-8";

/* ------------------------------- */
//
// 最近見たコンテンツ
//
/* ------------------------------- */
.common_inc.inc_history {
	.common_inc_main {
		position: relative;
		min-height: 342px;
		// padding: 60px 0 40px;
		@include mq(md4) {
			overflow-x: clip;
		}
		@include mq(sp) {
			height: auto;
			width: 100%;
		}
		.contents {
			margin-top: 0;
			// width: 1400px;
			max-width: 1115px;
			@include mq(md4) {
				position: absolute;
				width: 1115px;
				left: calc(50vw - 565px);
			}
			@include mq(sp) {
				width: auto;
				height: auto;
			}
		}
	}
}
.inc_history_ul {
	display: flex;
	justify-content: flex-start;
	width: 98%;
	box-sizing: border-box;
	max-width: 1115px;
	margin: 0 auto;
	@include mq(md4) {
		overflow-x: clip;
		max-width: 1115px;
	}
	@include mq(sp) {
		width: 92%;
		justify-content: space-between;
		flex-wrap: wrap;
	}
	.inc_history_li {
		width: pcnt(260, 1115);
		margin-left: pcnt(25, 1115);
		&:first-child {
			margin-left: 0;	
		}
		@include mq(md4) {
			overflow-x: clip;
			width: 1115px;
		}
		@include mq(sp) {
			margin: 0;
			width: 48%;
			&:nth-child(n+3) {
				margin-top: 5vw;
			}
		}
		> a {
			width: 100%;
			color: $defcolor;
			> dl {
				width: 100%;
				margin: 0 auto;
				@include mq(sp) {
					width: auto;
				}
				> dt {
					> figure {
						width: 100%;
						height: 180px;
						display: block;
						position: relative;
						overflow: hidden;
						box-sizing: border-box;
						border: #cccccc 1px solid;
						margin-bottom: 20px;
						// @include mq(md4) {
						// 	height: 14.7vw;
						// }
						@include mq(sp) {
							position: static;
							width: auto;
							height: auto;
						}
						> img {
							position: absolute;
							transform: scale(1);
							transition: transform .3s;
							@include mq(sp) {
								position: static;
							}
						}
					}
				}
				> dd {
					width: 100%;
					box-sizing: border-box;
					padding: 0;
					@include mq(sp) {
						width: auto;
						padding: 0;
					}
					.inctxt_history {
						&_ttl {
							font-size: 16px;
							margin-bottom: 0.4em;
							font-weight: bold;
		
							@include mq(sp) {
								font-size: spvw(28);
							}
						}
		
						&_txt {
							margin-bottom: 1.45em;
							font-size: 14px;
		
							@include mq(sp) {
								margin-bottom: spvw(25);
								font-size:spvw(28);
							}
						}
		
						&_ico {
							display: inline-block;
							font-size: 12px;
							border-radius: 1em;
							border: solid 1px #c8b9f9;
							color: #5330c1;
							background-color: #FFFFFF;
							padding: 0 0.8em;
							letter-spacing: -.05em;
							margin-bottom: .4em;
							margin-right: .6em;
							@include mq(sp) {
								font-size:spvw(20);
							}
						}
					}
				}
			}
			&:hover {
				text-decoration: none;
				> dl {
					> dt {
						> figure {
							> img {
								transform: scale(1.05);
								@include mq(sp) {
									transform: scale(1);
								}
							}
						}
					}
				}
			}
		}
	}
}
