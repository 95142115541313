@charset "UTF-8";

/* ------------------------------- */
//
// おすすめ製品・サービス
//
/* ------------------------------- */
.common_inc.inc_recommend {
	.common_inc_main {
		position: relative;
		min-height: 342px;
		// padding: 60px 0 40px;
		@include mq(md2) {
			overflow-x: clip;
		}
		@include mq(sp) {
			height: auto;
			width: 100%;
		}
		.contents {
			margin-top: 0;
			width: 1400px;
			max-width: 1400px;
			@include mq(md2) {
				position: absolute;
				left: calc(50vw - 700px);
			}
			@include mq(sp) {
				width: auto;
				height: auto;
			}
		}
		.slick-slide {
			@include mq(sp) {
				margin: 0 3.33vw;
			}
		}
		.slick-track {
			margin: 0;
		}
		.slick-dotted.slick-slider {
			@include mq(md2) {
				margin-bottom: 0px;
			}
		}
		.slick-dots {
			bottom: -40px;
			li {
				width: 40px;
				height: auto;
				@include mq(sp) {
					width: 8vw;
				}
				button {
					width: 40px;
					height: 3px;
					background-color: #ccc;
					padding: 0;
					@include mq(sp) {
						width: 8vw;
					}
					&:before {
						width: 0;
						height: 3px;
						background-color: #8973ce;
						content: '';
						border-radius: 3px;
						text-align: center;
						opacity: 1;
					}
				}
				&.slick-active {
					button {
						&:before {
							opacity: 1;
							animation: dotsanime 4s linear forwards;
						}
					}
				}
			}
		}
	}
}
.inc_recommend_ul {
	.inc_recommend_li {
		@include mq(sp) {
			margin: 0;
		}
		> a {
			color: $defcolor;
			> dl {
				width: 210px;
				margin: 0 auto;
				@include mq(sp) {
					width: auto;
				}
				> dt {
					> figure {
						width: 100%;
						height: 210px;
						display: block;
						position: relative;
						overflow: hidden;
						@include mq(sp) {
							position: static;
							width: auto;
							height: auto;
						}
						> img {
							position: absolute;
							transform: scale(1);
							transition: transform .3s;
							@include mq(sp) {
								position: static;
							}
						}
					}
				}
				> dd {
					width: 100%;
					box-sizing: border-box;
					padding: 0;
					@include mq(sp) {
						width: auto;
						padding: 0;
					}
					p {
						font-size: 12px;
						margin: 1em 0 0;
						@include mq(sp) {
							font-size: spvw(24);
						}
					}
				}
			}
			&:hover {
				text-decoration: none;
				> dl {
					> dt {
						> figure {
							> img {
								transform: scale(1.05);
								@include mq(sp) {
									transform: scale(1);
								}
							}
						}
					}
				}
			}
			&[target="_blank"] {
				> dl {
					> dd {
						p {
							position: relative;
							display: inline-block;
							&:after {
								content: "";
								position: absolute;
								width: 1.3em;
								height: 1.6em;
								right: -1.5em;
								bottom: -0.05em;
								display: inline-block;
								background: transparent url(/common/img/ico/ico_blank.svg) right top / contain no-repeat;
							}
						}
					}
				}
			}
		}
	}
}

body.ie {
	.inc_recommend_ul {
		.inc_recommend_li {
			> a {
				&[target="_blank"] {
					> dl {
						> dd {
							p {
								&:after {
									width: 1.2em;
									height: 1.2em;
									bottom: 0.5em;
									background: transparent url(/common/img/ico/ico_blank.png) center center / contain no-repeat;
								}
							}
						}
					}
				}
			}
		}
	}
}
