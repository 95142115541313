@charset "UTF-8";


.acrobat_box {
    max-width: 670px;
    width: pcnt(650, 670);
    margin: 90px auto;
    @include mq(sp) {
        width: 92%;
        margin: spvw(110) auto 0;
    }
    > p {
        font-size: 12px !important;
        letter-spacing: .02em;
        color: #2c2933;
        margin-top: 1.2em;
        line-height: 1.7em;
        @include mq(sp) {
            margin-top: .4em;
            font-size: spvw(22) !important;
        }
    }
    > dl {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        > dt {
            width: 158px;
            display: flex;
            align-items: center;
            @include mq(sp) {
                width: 100%;
            }
            figure {
                display: block;
                @include mq(sp) {
                    width: pcnt(330, 690);
                }
                a {
                    display: block;
                    opacity: 1;
                    transition: opacity .3s;
                    &:hover {
                        opacity: .7;
                    }
                    img {
                        display: block;
                        @include mq(sp) {
                            width: 100%;
                        }
                    }
                }
            }
        }
        > dd {
            width: calc(100% - 188px);
            @include mq(sp) {
                width: 100%;
            }
            p {
                font-size: 14px;
                margin: 0;
                letter-spacing: .02em;
                @include mq(sp) {
                    margin-top: .7em;
                    font-size: spvw(26);
                }
            }
        }
    }
}