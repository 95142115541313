@charset "utf-8";


body#error {

    .cec_h1ttl_02 > div {
        padding: 32px 40px 32px;
        @include mq(sp) {
            padding: spvw(50) spvw(40);
        }
    }

    .h1ttl_error {
        background: transparent url(/common/img/bg_h1_error_pc.jpg) center center / cover no-repeat;

        @include mq(md) {
        }

        @include mq(sp) {
            background: transparent url(/common/img/bg_h1_error_sp.jpg) center center / cover no-repeat;
        }
    }

    article {
        width: 92%;
        max-width: 1000px;
        margin: 80px auto 110px;
        @include mq(sp) {
            margin: spvw(80) auto;
        }
    }

    .lead {
        margin-bottom: 80px;
        text-align: center;
        line-height: 1.8;
        font-size: 16px;
        @include mq(sp) {
            margin-bottom: spvw(80);
            text-align: left;
            font-size: spvw(28);
        }
    }

    .btn_01 {
        @extend .cec_btn_01;
        line-height: 0.8em;
        width: 340px;
        @include mq(sp) {
            width: 80vw;
        }
        &:after {
            @include mq(sp) {
                right: -.7em;
                width: 2.8em;
            }
        }
    }

    .migration {
        padding-bottom: 0;
        margin-top: 110px;
        @include mq(sp) {
            margin-top: spvw(140);
        }

        &:before { display: none; }

        ul {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
        }

        li {
            display: flex;
            //個別[s]
            width: 23.5%;
            &:nth-child(n+5) { margin-top: 2%; }
            + li:not(:nth-child(4n+1)) { margin-left: 2%; }
            @include mq(sp) {
                width: spvw(310);
                &:nth-child(n+3) { margin-top: 1em; }
                + li:not(:nth-child(4n+1)) { margin-left: 0; }
                + li:nth-child(even) { margin-left: spvw(70); }
            }
            //個別[e]
        }

        a {
            @extend .cec_btn_01;
            width: 100%;
            @include mq(sp) {
                display: block;
                border-radius: 0;
                border: none;
                color: $black;
                text-align: left;
                line-height: 1.85;
                background: none;
            }
            &:before {
                width: 100%;
                background-color: $white;
                opacity: 1;
                height: calc(100% - 2px);
                width: calc(100% - 2px);
                top: 1px;
                left: 1px;
                z-index: 1;
                transition: none;
            }
            &:after {
                display: none;
                @include mq(sp) {
                    right: .2em!important;
                    top: .56em;
                    display: block;
                    width: 0.6em;
                    height: 0.6em;
                    background: none;
                    border-right: 1px solid $purple;
                    border-top: 1px solid $purple;
                    transform: rotateZ(45deg);
                }
            }
            &:hover {
                .migration_ttl {
                    color: $white;
                    @include mq(sp) {
                        color: $black;
                    }
                    &:before {
                        width: 100%;
                        opacity: 1;
                        @include mq(sp) {
                            width: 0%;
                            opacity: 0;
                        }
                    }
                }
            }
        }

        p {
            position: relative;
            z-index: 2;
            padding: 0;
        }

        &_ttl {
            position: relative;
            display: block;
            padding: .75em .5em .77em;
            letter-spacing: -.04em;
            font-size: 13px;
            font-weight: bold;
            @include mq(sp) {
                padding: 0 1em 0 0;
                border-radius: 0;
                font-size: spvw(28);
            }
            &::before {
                position: absolute;
                content: "";
                border-radius: 4em;
                opacity: 0;
                height: calc(100% + 2px);
                width: 0%;
                top: -1px;
                left: -1px;
                background: linear-gradient(90deg, rgba(83,48,193,1) 0%, rgba(135,106,228,1) 100%, rgba(0,212,255,1) 100%);
                transition: all .3s;
                z-index: -1;
            }
        }
    }
}


