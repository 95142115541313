@charset "UTF-8";




.tbl_box {
	overflow-x: auto;
	@include mq(sp) {
		padding-bottom: 3.2vw;
	}
}
.tblset_01 {
	th {
		border: 1px solid $glay7;
		background-color: $purple7;
		font-size: 14px;
		box-sizing: border-box;
		padding: 1em;
		text-align: center;
		font-weight: bold;
		@include mq(sp) {
			font-size: spvw(20);
		}
		&.thttl_01 {
			text-align: left;
			background-color: $glay;
		}
	}
	td {
		font-size: 14px;
		box-sizing: border-box;
		padding: 1em;
		text-align: left;
		border: 1px solid $glay7;
		@include mq(sp) {
			font-size: spvw(20);
		}
	}
}
.tblset_02 {
	tr {
		border-bottom: 1px solid $glay7;
		&:last-child {
			border-bottom: none;
		}
	}
	th {
		font-size: 14px;
		box-sizing: border-box;
		padding: 1em 1em 1em 0;
		text-align: left;
		font-weight: bold;
		white-space: nowrap;
		vertical-align: top;
		@include mq(sp) {
			font-size: spvw(20);
		}
	}
	td {
		font-size: 14px;
		box-sizing: border-box;
		padding: 1em;
		text-align: left;
		@include mq(sp) {
			font-size: spvw(20);
		}
	}
}
.tblset_03 {
	th {
		&.upttl {
			border-right: 1px solid $glay7;
			background-color: $purple7;
			font-size: 14px;
			box-sizing: border-box;
			padding: .5em;
			text-align: center;
			font-weight: bold;
			&:last-child {
				border-right: none;
			}
			@include mq(sp) {
				font-size: spvw(20);
			}
		}
	}
	td {
		font-size: 14px;
		box-sizing: border-box;
		padding: 1em 2em;
		text-align: left;
		border: 1px solid $glay7;
		@include mq(sp) {
			font-size: spvw(20);
		}
	}
}

.pc, .pc_only {
	display: block;
	@include mq(sp) {
		display: none;
	}
}
.sp, .sp_only {
	display: none;
	@include mq(sp) {
		display: block;
	}
}

.note01 {
	font-size: 12px;
	@include mq(sp) {
		font-size: spvw(20);
	}
}

.clearfix:after {
	display: block;
	content: "";
	clear: both;
}
.red { color: rgba(255,0,0,1); }
.lthrough { text-decoration: line-through; }

.wb_break { word-break: break-all; }
.wb_keep { word-break: keep-all; }
.wb_word { word-break: break-word; }

.nowrap { white-space: nowrap; }

.text_l { text-align: left; }
.text_c { text-align: center; }
.text_r { text-align: right; }
.b { font-weight: bold; }
.center { text-align: center; }
.fwN { font-weight: normal;}
.fwB { font-weight: bold;}
.fzSSS  { font-size:  60%;}/* base 12px ->  8px */
.fzSS  { font-size:  78%;}/* base 12px ->  8px */
.fzS   { font-size:  85%;}/* base 12px -> 10px */
.fzM   { font-size: 100%;}
.fzL   { font-size: 116.6%;}/* base 12px -> 14px */
.fzLL  { font-size: 133.3%;}/* base 12px -> 16px */
.fzLLL { font-size: 143%;}/* base 12px -> 18px */
sup   { font-size: 78%; vertical-align: top;}
sub   { font-size: 78%; vertical-align: baseline;}

.fs12 { font-size: 12px !important; @include mq(sp) { font-size: spvw(24) !important; } }
.fs14 { font-size: 14px !important; @include mq(sp) { font-size: spvw(28) !important; } }
.fs16 { font-size: 16px !important; @include mq(sp) { font-size: spvw(32) !important; } }

/* !anchor ------------------------------------------------------------ */
.anch_offset {
	margin-top: -75px !important;
	padding-top: 75px !important;
	display: block;
	height: 0;
	line-height: 0;
	@include mq(sp) {
		margin-top: -10vw !important;
		padding-top: 10vw !important;
	} 
}
/* !Inline Align ------------------------------------------------------------ */
.taL { text-align: left   !important;}
.taC { text-align: center !important;}
.taR { text-align: right  !important;}
.vaT { vertical-align: top    !important;}
.vaM { vertical-align: middle !important;}
.vaB { vertical-align: bottom !important;}
.vaT_pc { @include mq(pc) { vertical-align: top !important;} }
.vaM_pc { @include mq(pc) { vertical-align: middle !important;} }
.vaB_pc { @include mq(pc) { vertical-align: bottom !important;} }
.blockC { margin-right: auto; margin-left: auto; text-align: left;}
* html .blockC { margin-right: 0; margin-left: 0;}
.blockR { margin-left: auto; text-align: left;}
* html .blockR { margin-left: 0;}

/* !Width ------------------------------------------------------------------- */
.w100p  { width: 100%;}
/* 960px Grid */
.w960   { width: 960px;}
.w800	{ width: 800px;}
.w640	{ width: 640px;}
.w490	{ width: 490px;}
.w480	{ width: 480px;}
.w448	{ width: 448px;}
.w320	{ width: 320px;}
.w310	{ width: 310px;}
.w250	{ width: 250px;}
.w220	{ width: 220px;}
.w160	{ width: 160px;}

.wHalf  { width: 48%;}
.wTri   { width: 33%;}
.wQuart { width: 24%;}
.wFull  { width: 99.9%;}
.wMax   { width: 100%;}

.wa     { width: auto  !important;}
.w03per { width: 3%  !important;}
.w05per { width: 5%  !important;}
.w10per { width: 10% !important;}
.w13per { width: 13% !important;}
.w15per { width: 15% !important;}
.w20per { width: 20% !important;}
.w25per { width: 25% !important;}
.w30per { width: 30% !important;}
.w35per { width: 35% !important;}
.w40per { width: 40% !important;}
.w45per { width: 45% !important;}
.w50per { width: 50% !important;}

/* !Height ------------------------------------------------------------------- */
.h300 { height: 300px; }

/* !Floats ------------------------------------------------------------------ */
.flL    { display: inline; float: left;}
.flR    { display: inline; float: right;}
.flImgL { display: inline; float: left; margin-right: 30px;}
.flImgR { display: inline; float: right; margin-left: 30px;}
.ftBox  { overflow: hidden; *zoom: 1;}

/* !Tools ------------------------------------------------------------------- */

.clear::after {
	content: "";
	display: block;
	clear: both;
}
.clear { clear: both;}
.block { display: block;}
.flex { display: flex;}
.hide  { display: none;}
.bgN   { background: none !important;}
.tdU   { text-decoration: underline;}
.tdN   { text-decoration: none;}


/* !Border None ------------------------------------------------------------- */
.bdtN { border-top: none !important;}
.bdrN { border-right: none !important;}
.bdbN { border-bottom: none !important;}
.bdlN { border-left: none !important;}
/* !Margin ------------------------------------------------------------------ */
.mt00 { margin-top: 0   !important;}
.mt05 { margin-top: 5px !important;}
.mt10 { margin-top:10px !important;}
.mt12 { margin-top:12px !important;}
.mt15 { margin-top:15px !important;}
.mt20 { margin-top:20px !important;}
.mt25 { margin-top:25px !important;}
.mt30 { margin-top:30px !important;}
.mt35 { margin-top:35px !important;}
.mt40 { margin-top:40px !important;}
.mt45 { margin-top:45px !important;}
.mt50 { margin-top:50px !important;}
.mr00 { margin-right: 0   !important;}
.mr05 { margin-right: 5px !important;}
.mr10 { margin-right:10px !important;}
.mr12 { margin-right:12px !important;}
.mr15 { margin-right:15px !important;}
.mr20 { margin-right:20px !important;}
.mr25 { margin-right:25px !important;}
.mr30 { margin-right:30px !important;}
.mr35 { margin-right:35px !important;}
.mr40 { margin-right:40px !important;}
.mr45 { margin-right:45px !important;}
.mr50 { margin-right:50px !important;}
.mb00 { margin-bottom: 0   !important;}
.mb05 { margin-bottom: 5px !important;}
.mb10 { margin-bottom:10px !important;}
.mb12 { margin-bottom:12px !important;}
.mb15 { margin-bottom:15px !important;}
.mb20 { margin-bottom:20px !important;}
.mb25 { margin-bottom:25px !important;}
.mb30 { margin-bottom:30px !important;}
.mb35 { margin-bottom:35px !important;}
.mb40 { margin-bottom:40px !important;}
.mb45 { margin-bottom:45px !important;}
.mb50 { margin-bottom:50px !important;}
.ml00 { margin-left: 0   !important;}
.ml05 { margin-left: 5px !important;}
.ml10 { margin-left:10px !important;}
.ml12 { margin-left:12px !important;}
.ml15 { margin-left:15px !important;}
.ml20 { margin-left:20px !important;}
.ml25 { margin-left:25px !important;}
.ml30 { margin-left:30px !important;}
.ml35 { margin-left:35px !important;}
.ml40 { margin-left:40px !important;}
.ml45 { margin-left:45px !important;}
.ml50 { margin-left:50px !important;}

/* !Padding  ---------------------------------------------------------------- */
.pt00 { padding-top: 0   !important;}
.pt05 { padding-top: 5px !important;}
.pt10 { padding-top:10px !important;}
.pt12 { padding-top:12px !important;}
.pt15 { padding-top:15px !important;}
.pt20 { padding-top:20px !important;}
.pt25 { padding-top:25px !important;}
.pt30 { padding-top:30px !important;}
.pt35 { padding-top:35px !important;}
.pt40 { padding-top:40px !important;}
.pt45 { padding-top:45px !important;}
.pt50 { padding-top:50px !important;}
.pr00 { padding-right: 0   !important;}
.pr05 { padding-right: 5px !important;}
.pr10 { padding-right:10px !important;}
.pr12 { padding-right:12px !important;}
.pr15 { padding-right:15px !important;}
.pr20 { padding-right:20px !important;}
.pr25 { padding-right:25px !important;}
.pr30 { padding-right:30px !important;}
.pr35 { padding-right:35px !important;}
.pr40 { padding-right:40px !important;}
.pr45 { padding-right:45px !important;}
.pr50 { padding-right:50px !important;}
.pb00 { padding-bottom: 0   !important;}
.pb05 { padding-bottom: 5px !important;}
.pb10 { padding-bottom:10px !important;}
.pb12 { padding-bottom:12px !important;}
.pb15 { padding-bottom:15px !important;}
.pb20 { padding-bottom:20px !important;}
.pb25 { padding-bottom:25px !important;}
.pb30 { padding-bottom:30px !important;}
.pb35 { padding-bottom:35px !important;}
.pb40 { padding-bottom:40px !important;}
.pb45 { padding-bottom:45px !important;}
.pb50 { padding-bottom:50px !important;}
.pl00 { padding-left: 0   !important;}
.pl05 { padding-left: 5px !important;}
.pl10 { padding-left:10px !important;}
.pl12 { padding-left:12px !important;}
.pl15 { padding-left:15px !important;}
.pl20 { padding-left:20px !important;}
.pl25 { padding-left:25px !important;}
.pl30 { padding-left:30px !important;}
.pl35 { padding-left:35px !important;}
.pl40 { padding-left:40px !important;}
.pl45 { padding-left:45px !important;}
.pl50 { padding-left:50px !important;}

.ls-1 { letter-spacing: -1px; }
.ls-1half { letter-spacing: -1.5px; }
.ls-2 { letter-spacing: -2px; }

.tb-rl {
	-webkit-writing-mode: vertical-rl;
	-ms-writing-mode: tb-rl;
	writing-mode: vertical-rl;
	display: inline-block;
	white-space: nowrap;
}