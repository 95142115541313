@charset "UTF-8";

/* ------------------------------- */
//
// 更新情報
//
/* ------------------------------- */
.contents.w900.inc_news {
	text-align: right;
	@include mq(sp) {
		padding: 0;
	}
	a.newslist {
		display: inline-block;
		font-size: 14px;
		font-weight: 500;
		color: $purple;
		position: relative;
		line-height: 1em;
		margin: 2.5em 2em 0 0;
		@include mq(sp) {
			font-size: spvw(28);
		}
		&:after {
			content: '';
			position: absolute;
			right: -1.5em;
			top: .2em;
			display: inline-block;
			width: 0.6em;
			height: 0.6em;
			border-top: $purple 1px solid;
			border-right: $purple 1px solid;
			transform: rotateZ(45deg);
			transition: right .3s;
		}
		&:hover {
			text-decoration: none;
			&:after {
				right: -1.84em;
			}
		}
	}
	
}
ul {
	&.cmn_news_list {
		text-align: left;
		width: 100%;
		> li {
			width: 100%;
			margin-bottom: 25px;
			> dl {
				width: 100%;
				display: flex;
				justify-content: space-between;
				> dt {
					&.date {
						font-size: 12px;
						width: 87px;
						line-height: 1.8em;
						color: $glay5;
						margin-top: 0.3em;
					}
					&.category {
						width: 110px;
						>span{
							position: relative;
							border-radius: 1em;
							border: $purple6 1px solid;
							display: block;
							width: 100%;
							height: 1.5em;
							font-size: 12px;
							margin-top: 0.3em;
							&:after {
								font-size: 12px;
								letter-spacing: .05em;
								color: $purple;
								position: absolute;
								width: 100%;
								height: 100%;
								line-height: 1em;
								text-align: center;
								display: flex;
								align-items: center;
								justify-content: center;
							}
						}
						&.news {
							>span{
								border: #b1d0fc 1px solid;
								&:after {
									color: #70a9fa;
									content: "NEWS";
								}
							}
						}
						&.release {
							>span{
								border: #fcbdbd 1px solid;
								&:after {
									color: #f57f7f;
									content: "RELEASE";
								}
							}
						}
						&.ir {
							>span{
								border: #ace6e0 1px solid;
								&:after {
									color: #4bc5b9;
									content: "IR";
								}
							}
						}
					}
				}
				> dd {
					font-size: 14px;
					width: calc(100% - 230px);
					line-height: 2em;
					box-sizing: border-box;
					padding-left: .8em;
					> a {
						color: $defcolor;
						&:hover {
							color: $purple;
							text-decoration: none;
						}
					}
				}
			}
			&:last-child {
				margin-bottom: 0;
			}
		}
		&.products {
			> li {
				> dl {
					flex-wrap: wrap;
					justify-content: flex-start;
					> dt {
						&.date {
							line-height: 2.4em;
						}
						&.category {
							width: auto;
							margin-left: 16px;
							display: flex;
							align-items: center;
							>span{
								display: inline;
								font-size: 12px;
								color: $purple;
								line-height: 1em;
								height: auto;
								padding: .25em 2em .25em;
							}
						}
					}
					> dd {
						width: calc(100% - 87px);
						margin-left: 87px;
						line-height: 2em;
						> a {
							&:hover {
							}
						}
					}
				}
			}
		}
		
        @include mq(sp) {
			> li {
				margin-bottom: 4vw;
				> dl {
					display: flex;
					justify-content:left;
					flex-wrap: wrap;
					border-bottom: $glay4 1px solid;
					> dt {
						&.date {
							width: 25%;
							font-size: 2.8vw;
						}
						&.category {
							width: 30%;
							display: flex;
							align-items: center;
							>span{
								width: 100%;
								height: 1.5em;
								padding-top: 0.05em;
								font-size: 2.6vw;
								&:after {
									font-size: 2.6vw;
								}
							}
						}
					}
					> dd {
						font-size: 3.2vw;
						width: 100%;
						padding-left: 0;
						line-height: 1.6em;
						margin: .6em 0 1.2em;
						padding-right: 1em;
						box-sizing: border-box;
					}
				}
				&:last-child {
					dl {
						border-bottom: none;
					}
				}
			}
			&.products {
				> li {
					> dl {
						flex-wrap: wrap;
						justify-content: flex-start;
						> dt {
							&.date {
								width: 25%;
								font-size: 2.8vw;
							}
							&.category {
								width: auto;
								margin-left: 0;
								>span{
									font-size: 2.6vw;
									line-height: normal;
									padding: .1em 2em 0;
								}
							}
						}
						> dd {
							font-size: 3.2vw;
							width: 100%;
							padding-left: 0;
							line-height: 1.6em;
							margin: .6em 0 1.2em;
						}
					}
				}
			}
			
		}
	}
}

body.ie {
	ul {
		&.cmn_news_list {
			> li {
				> dl {
					> dt {
						&.category {
							>span{
								&:after {
									top: .2em;
								}
							}
						}
					}
				}
			}
		}
	}
}