@charset "utf-8";



@keyframes anime_ttl_deco_pc {
    0% { top: -25px; }
    30% { top: 0px; }
    100% { top: 0px; }
}
@keyframes anime_ttl_deco_sp {
    0% { top: spvw(-50); }
    30% { top: 0; }
    100% { top: 0; }
}

@keyframes anime_ttl_deco_pc2 {
    0% { top: -55px; }
    30% { top: -30px; }
    100% { top: -30px; }
}
@keyframes anime_ttl_deco_sp2 {
    0% { top: spvw(-125); }
    30% { top: spvw(-57.5); }
    100% { top: spvw(-57.5); }
}

.ttl_deco_before {
    content: "";
    display: block;
    position: absolute;
    left: 50%;
    top: -25px;
    width: 1px;
    height: 35px;
    background-color: $glay2;
	@include mq(sp) {
        top: spvw(-50);
        width: spvw(2);
        height: spvw(70);
    }
}
.ttl_deco_after {
    content: "";
    display: block;
    position: absolute;
    left: 50%;
    top: -25px;
    width: 1px;
    height: 10px;
    background-color: $orange;
    animation: anime_ttl_deco_pc 3s infinite;
	@include mq(sp) {
        top: spvw(-50);
        width: spvw(2);
        height: spvw(20);
        animation: anime_ttl_deco_sp 3s infinite;
    }
}

.ttl_deco_before2 {
    @extend .ttl_deco_before;
    top: -65px;
    height: 45px;
    @include mq(sp) {
        top: spvw(-125);
        height: spvw(90);
    }
}
.ttl_deco_after2 {
    @extend .ttl_deco_after;
    top: -55px;
    animation: anime_ttl_deco_pc2 3s infinite;
    @include mq(sp) {
        top: spvw(-100);
        animation: anime_ttl_deco_sp2 3s infinite;
        height: spvw(22.5);
    }
}

.flexwrap {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}




// ボタン
.cec_btn_01 {
    display: inline-block;
    position: relative;
    box-sizing: border-box;
    font-size: 14px;
    border-radius: 4em;
    text-align: center;
    // background-color: $white;
    color: $purple;
    // border: $purple 1px solid;
    width: auto;
    line-height: 1em;
    background: linear-gradient(90deg, rgba(83,48,193,1) 0%, rgba(135,106,228,1) 100%, rgba(0,212,255,1) 100%);
    &::before {
        position: absolute;
        content: "";
        border-radius: 4em;
        opacity: 1;
        height: calc(100% - 2px);
        width: calc(100% - 2px);
        top: 1px;
        left: 1px;
        // background: linear-gradient(90deg, rgba(83,48,193,1) 0%, rgba(135,106,228,1) 100%, rgba(0,212,255,1) 100%);
        background: $white;
        transition: all .3s;
        z-index: 2;
    }
    &::after {
        position: absolute;
        content: "";
        height: 1px;
        top: 50%;
        right: -1em;
        width: 42px;
        background-color: #a68df2;
        transition: right .2s;
        z-index: 4;
    }
    &:hover {
        color: $white;
        text-decoration: none;
        // &::before {
        //     opacity: 1;
        //     width: 100%;
        // }
        &::after {
            right: -1.34em;
        }
        > span {
            &::before {
                opacity: 1;
                width: calc(100% + 2px);
            }
        }
    }
    > span {
        display: block;
        text-align: center;
        width: 100%;
        height: 100%;
        border-radius: 4em;
        box-sizing: border-box;
        padding: 1.36em 2em;
        // overflow: hidden;
        position: relative;
        border: transparent 1px solid;
        font-weight: bold;
        position: relative;
        z-index: 3;
        word-break: break-all;
        &::before {
            position: absolute;
            content: "";
            border-radius: 4em;
            opacity: 0;
            height: calc(100% + 2px);
            width: 0%;
            top: -1px;
            left: -1px;
            background: linear-gradient(90deg, rgba(83,48,193,1) 0%, rgba(135,106,228,1) 100%, rgba(0,212,255,1) 100%);
            transition: all .3s;
            z-index: -1;
        }
    }
	@include mq(sp) {
        border-radius: spvw(60);
        font-size: spvw(28);
        &::before {
            // background: transparent;
            transition: none;
        }
        &:hover {
            color: $purple;
            // &::before {
            //     opacity: 1;
            //     width: 100%;
            // }
            &::after {
                right: -1em;
            }
            > span {
                &::before {
                    opacity: 0;
                    width: 100%;
                }
            }
        }
    }
}