@charset "UTF-8";


.contents {
	margin: 70px auto 40px;
	max-width: 1200px;
	width: 100%;
	padding-left: 20px;
	padding-right: 20px;
	box-sizing: border-box;
	&.w900 {
		max-width: 900px;
		margin-left: auto;
		margin-right: auto;
		box-sizing: border-box;
		width: 100%;
		padding-left: 20px;
		padding-right: 20px;
	}
	&.w780 {
		max-width: 780px;
		margin-left: auto;
		margin-right: auto;
		box-sizing: border-box;
		width: 100%;
		padding-left: 20px;
		padding-right: 20px;
	}
	@include mq(sp) {
		margin: 6vw auto 3vw;
		margin-left: auto;
		margin-right: auto;
		padding-left: 0;
		padding-right: 0;
		width: 92%;
	}
	p {
		margin-bottom: 1em;
	}
	img {
		width: 100%;
	}
}
h1 {
	&.h1seminar_01 {
		padding-left: .5em;
		margin-bottom: 1.1em;
		font-size: 24px;
		font-weight: bold;
		color: $black;
		border-left: $purple 5px solid;
		line-height: 1.7em;
		@include mq(sp) {
			font-size: calc((36 / 750) * 100vw);
		}
	}
	&.h1news_01 {
		padding-left: .5em;
		margin-bottom: 1.1em;
		font-size: 24px;
		font-weight: bold;
		color: $black;
		border-left: $purple 5px solid;
		line-height: 1.7em;
		@include mq(sp) {
			font-size: calc((36 / 750) * 100vw);
		}
		& + h2.h2news_01 {
			margin-top: -2em;
			padding-top: 1em;
		}
	}
	
}
h2 {
	&.h2seminar_01 {
		font-size: 18px;
		color: $black;
		font-weight: bold;
		margin-bottom: 1em;
		padding-left: 0.5em;
		line-height: 1.5em;
		border-left: $purple 5px solid;
		@include mq(sp) {
			font-size: calc((28 / 750) * 100vw);
		}
	}
	&.h2news_01 {
		font-size: 18px;
		color: $black;
		font-weight: bold;
		margin-bottom: 1em;
		padding-left: 0.5em;
		line-height: 1.5em;
		border-left: $purple 5px solid;
	}
	&.h2c_01 {
		text-align: center;
		position: relative;
		line-height: 1.6em;
		font-size: 28px;
		margin-bottom: 60px;
		@include mq(sp) {
			font-size: spvw(44);
			margin-bottom: spvw(60);
		}
		&::before {
			@extend .ttl_deco_before2;
		}
		&::after {
			@extend .ttl_deco_after2;
		}
	}
	&.h2c_02 {
		font-size: 22px;
		color: $purple;
		font-weight: bold;
		margin: 57px 0 45px;
		@include mq(sp) {
			font-size: calc((38 / 750) * 100vw);
			margin: calc((60 / 750) * 100vw) 0 calc((40 / 750) * 100vw);
		}
	}
}
h3 {
	&.h3seminar_01 {
		margin-bottom: 1em;
		font-size: 17px;
		font-weight: bold;
		line-height: 1.7;
		@include mq(sp) {
			font-size: calc((24 / 750) * 100vw);
		}
	}
	&.h3c_01 {
		margin-bottom: 1em;

	}
	&.h3c_02 {
		margin-bottom: 1em;
		text-align: left;	
	}
	&.h3c_03 {
		margin-bottom: 1em;
		font-size: 22px;
		color: $purple;
		font-weight: bold;
		margin: 1.7em 0;
		@include mq(sp) {
			font-size: calc((38 / 750) * 100vw);
			margin: calc((60 / 750) * 100vw) 0 calc((40 / 750) * 100vw);
		}
	}
}
h4 {
	&.h4c_01 {
		margin-bottom: 1em;
	}
	&.h4c_02 {
		margin-bottom: 1em;
		text-align: left;
	}
}


p {
	font-size: 14px;
	@include mq(sp) {
		font-size: calc((24 / 750) * 100vw);
	}
}


// 共通アコーディオンメニュー
.cec_accordion {
    margin: 35px 0;
    @include mq(sp) {
        margin: spvw(58) 0;
    }
    &:last-child { margin-bottom: 0; }
    &_ttl {
        position: relative;
        box-sizing: border-box;
        width: 100%;
        padding: .7em 2.5em .65em 1em;
        background: $white;
        border-width: 0 0 1px;
        border-style: solid;
        border-color: $glay7;
        text-align: left;
        font-size: 18px;
        line-height: 1.64;
        font-weight: bold;
        cursor: pointer;
        transition: all .2s;
        @include mq(sp) {
            font-size: spvw(34);
        }
        &:first-child { border-top-width: 1px; }
        &:before, &:after {
            content: '';
            position: absolute;
            top: 50%;
            right: 1em;
            width: 1em;
            height: 1px;
            background: $purple;
        }
        &:after {
            transform: rotateZ(90deg);
            transition: transform .2s;
        }
        &:hover, &.open {
            background: $purple8;
            border-color: $purple8;
        }
        &.open {
            &:after { transform: rotateZ(180deg); }
            // + .accordion_dtl { display: block; }
        }
    }
    &_dtl {
        display: none;
        padding: 1.6em 0 2.5em;
        border-bottom: 1px solid $glay7;
        @include mq(sp) {
            padding: 5vw 0 10.7vw;
        }
    }
}

ul {
	&.cec_list {
		> li {
			position: relative;
			padding-left: 1.1em;
			margin-bottom: 0.5em;
	
			&:last-of-type {
				margin-bottom: 0;
			}
	
			&:before {
				position: absolute;
				left: 0;
			}
		}
		&_disc {
			> li {
				&:before {
					content: "●";
					// top: 0.15em;
					color: $purple6;
				}
			}
		}
		&_check {
			> li {
				&:before {
					content: "";
					background: url(/common/img/ico/ico_check.svg) no-repeat 0 0 / contain;
					top: 0.3em;
					width: .8em;
					height: .8em;
				}
			}
		}
		&_number {
			> li {
				padding-left: 1.6em;
	
				&:before {
					content: "";
					background-color: transparent;
					background-position: center center;
					background-size: 95%;
					background-repeat: no-repeat;
					top: 0.1em;
					width: 1.3em;
					height: 1.3em;
				}
	
				@for $i from 1 through 20 {
					&:nth-child(#{$i}):before {
						background-image: url(/common/img/ico/ico_ol#{$i}.svg);
					}
				}
			}
		}
	}
}
body.ie {
	ul {
		&.cec_list {
			&_disc {
				> li {
					&:before {
						top: -0.1em;
					}
				}
			}
		}
	}
}


// 製品・サービスに関するお問い合わせ
.cases_contact {
    background: transparent url(/common/img/bg_contact.png) center bottom / cover no-repeat;
    padding: 0 20px 70px;
    margin-top: 150px;

    @include mq(sp) {
        padding: 0 0 spvw(95);
        margin-top: spvw(150);
    }

    >.contents {
        background-color: #fff;
        padding: 20px 0 40px;
        margin-bottom: 0;
        text-align: center;

        @include mq(sp) {
            padding-top: spvw(50);
            padding-bottom: spvw(50);
            width: 92%;
        }

        a {
            &.cec_btn_01 {
                width: 40%;

                @include mq(sp) {
                    width: 80%;
                }

                span {
                    padding: 1.2em 2em;
                }
            }
        }

        >dl {
            display: flex;
            justify-content: space-between;
            width: calc((500% * 100) / 860);
            margin: 40px auto 0;

            @include mq(sp) {
                display: block;
                margin: spvw(50) auto 0;
                width: 100%;
            }

            >dt {
                width: calc((160% * 100) / 500);

                @include mq(sp) {
                    width: calc((330% * 100) / 690);
                    margin: 0 auto 1em;
                }

                >img {
                    display: block;
                }
            }

            >dd {
                width: calc((320% * 100) / 500);

                @include mq(sp) {
                    width: 100%;
                }

                p {
                    letter-spacing: -.05em;
                }
            }
        }
    }
}