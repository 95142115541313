@charset "utf-8";


.g_clsbtn {
	position: absolute;
	top: 21px;
	right: 57px;
	width: 22px;
	height: 22px;
	&:before {
		position: absolute;
		border-radius: 3px;
		content: "";
		height: 2px;
		width: 29px;
		top: 0;
		left: 0;
		background-color: $white;
		transform: rotate(45deg);
		transform-origin: left;
	}
	&:after {
		position: absolute;
		border-radius: 3px;
		content: "";
		height: 2px;
		width: 29px;
		bottom: 0;
		left: 0;
		background-color: $white;
		transform: rotate(-45deg);
		transform-origin: left;
	}
}

// header
.h_menubg {
	position: fixed;
	width: 100vw;
	height: 100vh;
	z-index: 95;
	background-color: $black;
	opacity: 0;
	pointer-events: none;
	top: 109px;
	transition: all .4s;
	&.act {
		opacity: .8;
		pointer-events: all;
	}
	@include mq(sp) {
		top: 14vw;
		z-index: 110;
	}
}
header {
	width: 100%;
	position: fixed;
	z-index: 100;
	top:0;
	left:0;
	background-color: $white;
	line-height: 1em;
	&:after {
		position: absolute;
		content: "";
		width: 100%;
		height: 25px;
		background: transparent url(../img/bg_gradation.png) left top repeat-x;
		left: 0;
		bottom: -25px;
		opacity: 0;
		transition: opacity .8s;
		z-index: +1;
	}
	img {
		width: 100%;
	}
	.h_utility {
		width: 100%;
		position: relative;
		.h_logo {
			@include w(1160);
			margin: 0 auto;
			padding: 15px 0 0 5px;
			transition: padding .2s;
			box-sizing: border-box;
			a {
				@include clearText();
				width: 100px;
				height: 50px;
				display: block;
				background: transparent url(/common/img/headfoot/h_logo.svg) right bottom / 80% no-repeat;
			}
		}
		> ul {
			position: absolute;
			right: calc(50% - 580px);
			top: 15px;
			display: flex;
			transition: top .2s;
			> li {
				&.h_lang {
					> a {
						display: block;
						padding: 1em 0;
						font-size: 12px;
						margin-right: 24px;
						&:hover {
							color: $purple;
						}
					}
				}
				&.h_contact {
					> a {
						color: $white;
						font-size: 14px;
						display: block;
						background-color: $purple;
						border: $purple 1px solid;
						border-radius: 20px;
						padding: .6em 3.6em;
						box-sizing: border-box;
						transition: all .2s;
						&:hover {
							color: $purple;
							background-color: $white;
							text-decoration: none;
						}
					}
				}
				&.h_search {
					> a,> i {
						display: block;
						padding: 8px 8px 8px 28px;
						.h_searchbox {
							width: 22px;
							height: 22px;
							svg {
								fill: $purple;
								transition: all .2s;
							}
						}
						&:hover {
							.h_searchbox {
								svg {
									fill: $purple2;
								}
							}
						}
					}
				}
				&.h_humb {
					display: none;
				}
			}
		}
	}
	.h_main {
		@include w(1160);
		margin: 0 auto;
		nav {
			width: 100%;
			display: flex;
			display : -webkit-flex;
			justify-content: right;
			-webkit-justify-content: right;
			> ul {
				display: flex;
				margin-left: auto;
				> li {
					> a,> i {
						padding: 0 1.1em;
						font-size: 14px;
						display: block;
						color: $defcolor;
						> span {
							display: block;
							padding: 5px 0 22px;
							text-decoration: none;
							transition: padding .2s, height .2s;
							height: 43px;
							box-sizing: border-box;
						}
					}
					&.act {
						> a,> i {
							> span {
								color: $purple;
								font-weight: 700;
							}
						}
					}
					&.opn {
						> a,> i {
							> span {
								color: $purple;
								font-weight: 700;
								border-bottom: $purple3 3px solid;
							}
						}
					}
					&:hover {
						> a,> i {
							text-decoration: none;
							> span {
								cursor: pointer;
								color: $purple;
							}
						}
					}
					&.hnav_corporate {
						> a,> i {
							padding: 0 0 0 1.1em;
						}
					}
					// &.hnav_lang {
					// 	display: none;
					// }
					// &.hnav_contact {
					// 	display: none;
					// }
				}
			}	
		}
	}
	.h_sub {
		position: relative;
		width: 100%;
		.h_subnav {
			position: absolute;
			z-index: 98;
			width: 100%;
			background-color: $purple4;
			max-height: 0;
			overflow: hidden;
			transition: max-height .3s;
			.h_subback {
				display: none;
			}
			.h_subttl {
				position: relative;
				text-align: center;
				background-color: $purple3;
				.h_clsbtn {
					@extend .g_clsbtn;
				}
				p {
					padding: 1.5em 0;
					> a {
						color: $white;
						font-size: 14px;
					}
				}
			}
			.h_submain {
				margin: 70px auto 0;
				width: 760px;
				.h_clsbtn {
					@extend .g_clsbtn;
				}
				&.hsearch {
					width: 860px;
					@include mq(sp) {
						width: 100%;
					}
					.h_clsbtn {
						
						@include mq(sp) {
							right: 20px;
						}
					}
				}
				.h_inputword {
					display: flex;
					justify-content: center;
					align-items: center;
					margin: 0 auto 45px;
					label {
						position: relative;
						max-width: 650px;
						width: 100%;
						input {
							width: 100%;
							border-radius: 2.5em;
							padding: .8em 3em .8em 1.6em;
							font-size: 16px;
							box-sizing: border-box;
							border: none;
							&[type="text"] {
								border: none;
								outline: none;
								background-color: $white !important;
								&:focus {
									border: none;
								}
							}
							&[type="submit"] {
								position: absolute;
								top: 50%;
								right: 2em;
								transform: translateY(-50%);
								width: 1.25em;
								height: 1.25em;
								padding: 0;
								// background: url(/common/img/ico/ico_search.svg) no-repeat 0 50%/100% auto;
								text-indent: -9999px;
								cursor: pointer;
								background: transparent url(/common/img/ico/ico_search.svg) center center / 1.2em no-repeat;
								&:focus {
									border: none;
									outline: none;
								}
							}
						}
					}
					@include mq(sp) {
						width: spvw(690);
						margin: 16vw auto spvw(100);
						label {
							width: 100%;
							input {
								font-size: spvw(32);
								&[type="submit"] {
									background: transparent url(/common/img/ico/ico_search.svg) center center / cover no-repeat !important;
								}
							}
						}
					}
			        .poplink.pcia {
			            width: 100%;
			            padding-top: 5px;
			        }

			        .poplink_suggest {
			            width: 92.6%;
			            margin: 0 auto;
			            border: none;

			            .word {
			                padding: .45em 1em;
			                font-size: 14px;
			                @include mq(sp) {
			                    padding: 1em 1.5em;
			                    font-size: spvw(28);
			                }

			                // &.highlighted, &.hovered { background: transparent; }
			                &.highlighted, &.hovered { background: #f5f2ff; }
			                &:hover { background: $purple8; }
			            }
			        }
				}
				> ul {
					display: flex;
					flex-wrap: wrap;
					> li {
						width: 25%;
						margin-bottom: 25px;
						a {
							color: $white;
							font-size: 12px;
							&:hover {
								text-decoration: underline;
							}
						}
					}
					&.h_s_ul {
						display: flex;
						flex-wrap: wrap;
						justify-content: left;
						@include mq(sp) {
							margin: 0 auto;
							width: pcnt(690, 750);
							justify-content: space-between;
						}
						> li {
							display: inline-block;
							position: relative;
							box-sizing: border-box;
							font-size: 12px;
							border-radius: 2.5em;
							text-align: center;
							background-color: $white;
							color: $purple;
							border: transparent 1px solid;
							width: auto;
							line-height: 1em;
							width: 23.3%;
							margin-bottom: 0;
							margin-right: 2.2%;
							@include mq(sp) {
								width: pcnt(335, 690);
								margin-right: 0;
								margin-bottom: 0;
							}
							&:nth-child(n+5) {
								margin-top: 22px;
								@include mq(sp) {
									margin-top: 0;
								}
							}
							&:nth-child(n+3) {
								@include mq(sp) {
									margin-top: spvw(32);
								}
							}
							&:nth-child(4n) {
								margin-right: 0;
							}
							&::before {
								position: absolute;
								content: "";
								border-radius: 25px;
								opacity: 0;
								height: calc(100% + 2px);
								width: 0%;
								top: -1px;
								left: 0;
								background: linear-gradient(90deg, rgba(83,48,193,1) 0%, rgba(135,106,228,1) 100%, rgba(0,212,255,1) 100%);
								transition: all .3s;
							}
							&:hover {
								color: $white;
								text-decoration: none;
								&::before {
									opacity: 1;
									width: 102%;
									left: -1%;
								}
							}
							> a,
							> span {
								display: block;
								text-align: center;
								width: 100%;
								height: 100%;
								border-radius: 25px;
								box-sizing: border-box;
								padding: .6em 0 .7em;
								overflow: hidden;
								position: relative;
								border: transparent 1px solid;
								font-weight: bold;
								cursor: pointer;
								font-size: 12px;
								color: $purple;
								@include mq(sp) {
									font-size: spvw(24);
									letter-spacing: -.02em;
								}
								&:hover {
									text-decoration: none;
									color: $white;
								}
							}
							@include mq(sp) {
								border-radius: spvw(60);
								font-size: spvw(28);
								&::before {
									background: transparent;
									transition: none;
								}
								&:hover {
									color: $purple;
									&::before {
										opacity: 1;
										width: 100%;
									}
									&::after {
										right: -1em;
									}
								}
							}
						}
					}
					&.busi {
						width: 780px;
						justify-content: space-between;
						margin: 0 auto;
						> li {
							width: 31%;
							a {
								span {
									margin-bottom: 1.2em;
									display: inline-block;
									font-size: 12px;
								}
								figure {
									width: 100%;
									opacity: 1;
									transition: opacity .3s;
								}
								&:hover {
									span {
										text-decoration: underline;
									}
									figure {
										opacity: .7;
									}
								}
							}
						}
					}
				}
			}
			&.act {
				padding-bottom: 40px;
				max-height: 300px;
				&.h_sub_search {
					max-height: 402px;
					padding-bottom: 70px;
					@include mq(sp) {
						max-height: none;
					}
				}
			}
			p.todetail {
				padding-right: 5em;
				text-align: right;
				a {
					font-size: 12px;
					color: $white;
					&:hover {
						text-decoration: underline;
					}
				}
			}
		}
	}
	// スクロール時
	&.sc {
		&:after {
			opacity: 1;
		}
		.h_utility {
			> ul {
				top: 10px;
				right: calc(50% - 580px);
			}
			.h_logo {
				padding-top: 5px;
			}
		}
		.h_main {
			nav {
				> ul {
					> li {
						> a,> i {
							> span {
								padding: 4px 0 12px;
								height: 32px;
							}
						}
					}
				}	
			}
		}
	}
	// 768-1160
	@include mq(md) {
		.h_utility {
			> ul {
				right: 0;
			}
		}
		.h_main {
			nav {
				> ul {
					> li {
						> a,> i {
							padding: 0 calc((1300vw / 392) - 22.47px);
							font-size: calc((300vw / 392) + 5.122445px);
						}
						&.hnav_corporate {
							> a,> i {
								padding: 0 0 0 calc((1300vw / 392) - 22.47px);
							}
						}
					}
				}	
			}
		}
		&.sc {
			.h_utility {
				> ul {
					right: 0;
				}
			}
		}
	}
	// -768
	@include mq(sp) {
		width: 100%;
		background-color: $white;
		.h_utility {
			width: 100%;
			position: relative;
			@include clearfix;
			.h_logo {
				width: spvw(140);
				height: spvw(70);
				margin-left: 4vw;
				padding: spvw(20) 0 0 0;
				position: relative;
				z-index: 112;
				box-sizing: unset;
				a {
					width: 100%;
					height: 100%;
					background: transparent url(/common/img/headfoot/h_logo.svg) center center / 74% no-repeat;
				}
			}
			> ul {
				width: auto;
				text-align: right;
				float:right;
				right: 0;
				text-align: right;
				top: 0;
				display: flex;
				background-color: $white;
				justify-content: right;
				> li {
					&.h_lang {
						opacity: 0;
						pointer-events: none;
						transition: all .3s;
						position: fixed;
						display: flex;
						bottom: 14vw;
						z-index: 107;
						width: 100vw;
						background-color: $glay;
						left: 0;
						text-align: center;
						justify-content: center;
						height: 14vw;
						align-items: center;
						> a {
							font-size: 4vw;
						}
					}
					&.h_contact {
						opacity: 0;
						pointer-events: none;
						transition: all .3s;
						position: fixed;
						display: flex;
						bottom: 0;
						z-index: 107;
						width: 100vw;
						background-color: $purple;
						left: 0;
						text-align: center;
						justify-content: center;
						height: 14vw;
						align-items: center;
						> a {
							font-size: 4vw;
							background-color: transparent;
							border: transparent;
							&:hover {
								color: $white;
								background-color: $purple;
								text-decoration: none;
							}
						}
					}
					&.h_search {
						> a,> i {
							display: block;
							box-sizing: border-box;
							padding: 3.5vw;
							width: 14vw;
							height: 14vw;
							.h_searchbox {
								width: 7vw;
								height: 7vw;
								svg {
									fill: $purple;
									transition: all .2s;
								}
							}
							&:hover {
								.h_searchbox {
									svg {
										fill: $purple2;
									}
								}
							}
						}
					}
					&.h_humb {
						display: block;
						box-sizing: border-box;
						padding: 3.5vw;
						width: 14vw;
						height: 14vw;
						.humb {
							position: relative;
							width: 7vw;
							height: 7vw;
							> i {
								position: absolute;
								// height: .55vw;
								height: 2px;
								width: 100%;
								left: 0;
								border-radius: 4px;
								background-color: $purple;
								transform-origin: left;
								transition: all .2s;
								&:first-child {
									top: 5%;
									width: 100%;
								}
								&:nth-child(2) {
									top: 40%;
									width: 80%;
									opacity: 1;
								}
								&:last-child {
									top: 75%;
									width: 60%;
								}
							}
						}
					}
				}
			}
		}
		.h_main {
			@include w(1160);
			margin: spvw(15) auto 0;
			padding-top: 0;
			nav {
				display: block;
				position: relative;
				height: 0;
				background-color: $white;
				opacity: 0;
				transition: opacity .3s;
				overflow-y: auto;
				overflow-x: hidden;
				pointer-events: none;
				> ul {
					display: block;
					position: absolute;
					width: 100vw;
					left: 100vw;
					> li {
						> a,> i {
							padding: spvw(32);
							font-size: spvw(30);
							display: block;
							> span {
								padding: 0;
								position: relative;
								height: auto;
								&::after {
									content: "";
									position: absolute;
									right: .2em;
									top: .2em;
									width: .7em;
									height: .7em;
									border-top: 1px solid $purple;
									border-right: 1px solid $purple;
									transform: rotate(45deg);
								}
							}
						}
						&.opn {
							> a,> i {
								> span {
									color: inherit;
									font-weight: normal;
									border-bottom: none;
								}
							}
						}
						&.act {
							> a,> i {
								> span {
									// color: $defcolor;
									border-bottom: none;
								}
							}
						}
						&:hover {
							> a,> i {
								> span {
									color: $purple;
								}
							}
						}
						&.hnav_corporate {
							> a,> i {
								padding: spvw(32);
							}
						}
						// &.hnav_lang {
						// 	display: block;
						// 	margin-top: 10vw;
						// 	background-color: $glay;
						// 	> a,> i {
						// 		text-align: center;
						// 		padding: spvw(32);
						// 		span::after {
						// 			display: none;
						// 		}
						// 	}
						// }
						// &.hnav_contact {
						// 	display: block;
						// 	background-color: $purple;
						// 	> a,> i {
						// 		color: $white;
						// 		text-align: center;
						// 		padding: spvw(32);
						// 		span::after {
						// 			display: none;
						// 		}
						// 	}
						// }
					}
				}	
			}
		}
		.h_sub {
			position: fixed;
			top: 14vw;
			z-index: 130;
			width: spvw(580);
			right: spvw(-580);
			transition: right .2s;
			// height: calc(100vh - 12vw - 20vw);
			// height: calc(100vh - 12vw - 14vw);
			height: calc(100vh);
			padding-bottom: 30vw;
			overflow: hidden;
			&.actsearch {
				width: 100%;
				.h_subnav.act {
					height: auto;
				}
			}
			.h_subnav {
				background-color: $purple;
				max-height: none;
				height: 100%;
				overflow: hidden;
				opacity: 0;
				pointer-events: none;
				.h_subback {
					background-color: $purple5;
					padding: 1em;
					z-index: 20000;
					width: spvw(580);
					right: spvw(-580);
					bottom: 0;
					position: fixed;
					box-sizing: border-box;
					display: flex;
					align-items: center;
					height: 14vw;
					transition: right .2s;
					p {
						font-size: spvw(30);
						color: $white;
						position: relative;
						padding-left: 1.2em;
						&::after {
							content: "";
							position: absolute;
							height: .5em;
							width: .5em;
							top: .2em;
							left: .2em;
							border-left: $white 1px solid;
							border-top: $white 1px solid;
							transform: rotate(-45deg);
						}
					}
				}
				&.h_sub_search {
					.h_subback {
						display: none;
					}
				}
				.h_subttl {
					text-align: left;
					box-sizing: border-box;
					padding-left: 2em;
					p {
						padding: 1.5em 0;
						font-size: spvw(30);
						> a {
							color: $white;
							position: relative;
							display: inline-block;
							width: 100%;
							font-size: inherit;
							&:after {
								content: "";
								position: absolute;
								height: 0.6em;
								width: 0.6em;
								top: 0.2em;
								right: 1em;
								border-top: 1px $white solid;
								border-right: 1px $white solid;
								transform: rotate(45deg);
							}
						}
					}
					.h_clsbtn {
						display: none;
					}
				}
				&.act {
					max-height: none;
					opacity: 1;
					pointer-events: all;
					overflow: auto;
				}
				.h_submain {
					margin: spvw(50) auto;
					width: 100%;
					> ul {
						display: block;
						li {
							width: 100%;
							margin-bottom: 7vw;
							> a {
								font-size: spvw(24);
								padding-left: 4em;
							}
						}
						&.busi {
							width: 100%;
							> li {
								width: 74%;
								margin: 0 auto 4vw;
								> a {
									display: block;
									padding-left: 0;
									span {
										font-size: spvw(24);
									}
								}
							}
						}
					}
					&.detail {
						margin: spvw(50) auto spvw(70);
					}
					&.business {
						overflow-y: scroll;
						height: 68vh;
					}
				}
				&.h_sub_ir {
					.h_submain {
						ul {
							margin-bottom: 50vh;
						}
					}
				}
				p.todetail {
					padding-right: 2em;
					// margin-bottom: calc((70 / 750) * 100vw);
					padding-bottom: 90vw;
					a {
						font-size: calc((24 / 750) * 100vw);
					}
				}
			}
			&.act {
				right: 0;
				.h_subnav {
					.h_subback {
						right: 0;
					}
				}
			}
		}
		&.act {
			.h_utility {
				> ul {
					> li {
						&.h_lang {position: fixed;
							opacity: 1;
							pointer-events: all;
						}
						&.h_contact {
							opacity: 1;
							pointer-events: all;

						}
						&.h_humb {
							.humb {
								> i {
									&:first-child {
										top: 5%;
										width: 115%;
										transform: rotate(45deg);
									}
									&:nth-child(2) {
										opacity: 0;
									}
									&:last-child {
										top: 85%;
										width: 115%;
										transform: rotate(-45deg);
									}
								}
							}
						}
					}
				}
			}
			.h_main {
				nav {
					opacity: 1;
					pointer-events: all;
					height: calc(100vh - 12vw - 28vw);
					> ul {
						left: 0;
						padding: 5vw 0;
					}	
				}
			}
		}
		&.sc {
			&:after {
				display: none;
			}
		}
	}
}





// footer
footer {
	width: 100%;
	height: 50px;
	background-color: #373247;
	position: relative;
	.f_main {
		@include w(1160);
		height: 100%;
		margin: 0 auto;
		display: flex;
		align-items: center;
		color: #fff;
		.f_logo {
			@include clearText();
			width: 80px;
			height: 18.5px;
			margin-left: 15px;
			background: transparent url(/common/img/headfoot/f_logo.svg) center center / 100% no-repeat;
		}
		p.f_copy {
			font-family: '游ゴシック', YuGothic, 'Hiragino Kaku Gothic ProN', Meiryo, Helvetica Neue, Helvetica, Arial, sans-serif;
			font-size: 10px;
			margin-left: 40px;
			letter-spacing: .1em;
		}
		nav {
			margin-left: auto;
			> ul {
				display: flex;
				> li {
					padding: 0 18px;
					line-height: 1em;
					border-right: 1px #fff solid;
					a {
						color: #fff;
						font-size: 10px;
						display: block;
						line-height: 1em;
						text-decoration: none;
						&:hover {
							text-decoration: underline;
						}
					}
					&:last-child {
						padding: 0 0 0 18px;
						border-right: none;
					}
				}
			}
		}
	}
	@include mq(sp) {
		height: auto;
		padding: spvw(50) 0;
		box-sizing: border-box;
		.f_main {
			display: flex;
			flex-wrap: wrap;
			.f_logo {
				order: 2;
				@include sp_w(192);
				@include sp_h(44);
				margin: spvw(40) auto 0;
			}
			p.f_copy {
				order: 3;
				text-align: center;
				margin: spvw(35) spvw(54) 0;
				font-size: spvw(20);
				line-height: 1.5em;
			}
			nav {
				order: 1;
				margin: 0 auto;
				text-align: center;
				display: flex;
				> ul {
					margin: 0 auto;
					> li {
						padding: 0 .6em;
						a {
							font-size: spvw(20);
						}
						&:last-child {
							padding: 0 .7em;
							border-right: none;
						}
					}
				}
			}
		}
	}
}



#cmn_totop {
	display: block;
	position: fixed;
	z-index: 2;
	bottom: 25px;
	right: 10px;
	width: 50px;
	height: 50px;
	cursor: pointer;
	pointer-events: none;
	opacity: 0;
	transition: opacity .6s;
	@include mq(sp) {
		bottom: spvw(30);
		right: spvw(30);
		width: spvw(90);
		height: spvw(90);
	}
	> span {
		display: block;
		@include clearText();
		width: 100%;
		height: 100%;
		position: relative;
		border-radius: 100%;
		background-color: rgba(118,92,204,1);
		transition: background-color .4s;
		&:after {
			position: absolute;
			content: "";
			width: 30%;
			height: 30%;
			left: 35%;
			top: 35%;
			z-index: +1;
			background: transparent url(/common/img/ico/arrow_totop.png) center center / contain no-repeat;
			transform: translateY(0);
			transition: transform .3s;
		}
	}
	@include mq(pc) {
		&:hover {
			> span {
				background-color: rgba(137,115,206,1);
				&:after {
					transform: translateY(-5px);
				}
			}
		}
	}
	&.act {
		pointer-events: all;
		opacity: 1;
		@include mq(sp) {
			opacity: .8;
		}
	}
	&.spfoot {
		position: absolute;
		bottom: spvw(258);
		opacity: 1;
	}
}




// スマホ chrome 対応
// @include mq(sp) {
// 	body.sp_chrome {
// 		header {
// 			.h_sub {
// 				.h_subnav {
// 					p.todetail {
// 						padding-bottom: calc((320 / 750) * 100vw);
// 					}
// 					&.h_sub_ir {
// 						.h_submain {
// 							padding-bottom: calc((170 / 750) * 100vw);
// 						}
// 					}
// 				}
// 			}
// 		}
// 	}
// }

