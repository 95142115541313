@charset "UTF-8";


/* ------------------------------- */
//
// 導入事例
//
/* ------------------------------- */
.common_inc.inc_ccarousel {
	.common_inc_main {
		position: relative;
		min-height: 342px;
		// padding: 60px 0 40px;
		@include mq(md2) {
			overflow-x: clip;
		}
		@include mq(sp) {
			height: auto;
			width: 100%;
		}
		.contents {
			margin-top: 0;
			width: 1400px;
			max-width: 1400px;
			@include mq(md2) {
				position: absolute;
				left: calc(50vw - 700px);
			}
			@include mq(sp) {
				width: auto;
				height: auto;
			}
		}
		.slick-slide {
			@include mq(sp) {
				margin: 0 3.33vw;
			}
		}
		.slick-track {
			margin: 0;
		}
		.slick-dotted.slick-slider {
			@include mq(md2) {
				margin-bottom: 0px;
			}
		}
		.slick-dots {
			bottom: -40px;
			li {
				width: 40px;
				height: auto;
				@include mq(sp) {
					width: 8vw;
				}
				button {
					width: 40px;
					height: 3px;
					background-color: #ccc;
					padding: 0;
                    @include mq(sp) {
                        width: 8vw;
                    }
					&:before {
						width: 0;
						height: 3px;
						background-color: #8973ce;
						content: '';
						border-radius: 3px;
						text-align: center;
						opacity: 1;
					}
				}
				&.slick-active {
					button {
						&:before {
							opacity: 1;
							animation: dotsanime 4s linear forwards;
						}
					}
				}
			}
		}
	}
}
.ccarousel_intro {

	.contents {
		width: auto;
		max-width:1040px;

		@include mq(sp) {
			margin: 0;
			width: 100%;
		}
	}
	.inc_ccarousel_ul {
        .inc_ccarousel_li {
            @include mq(sp) {
                margin: 0;
            }
            > a {
                color: $defcolor;
                > dl {
                    width: 320px;
                    margin: 0 auto;
                    @include mq(sp) {
                        width: auto;
                    }
                    > dt {
                        > figure {
                            display: block;
                            width: 100% !important;
                            height: 230px !important;
                            margin: 0 0 20px !important;
                            position: relative;
                            overflow: hidden !important;
                            @include mq(sp) {
                                position: static;
                                width: auto !important;
                                height: auto !important;
                            }
                            > img {
                                position: absolute;
                                transform: scale(1);
                                transition: transform .3s;
                                @include mq(sp) {
                                    position: static;
                                }
                            }
                        }
                    }
                    > dd {
                        width: 100%;
                        box-sizing: border-box;
                        padding: 0;
                        @include mq(sp) {
                            width: auto;
                            padding: 0;
                        }
                    }
                }
                .inctxt_ccarousel {
                    &_ttl {
                        font-size: 16px;
                        margin-bottom: 0.4em;
                        font-weight: bold;
                        letter-spacing: -.02em;
                        @include mq(sp) {
                            font-size: spvw(28);
                        }
                    }
    
                    &_subTtl {
                        font-size: 12px;
                        color: #747377;
                        margin-bottom: .8em;
    
                        @include mq(sp) {
                            margin-bottom:spvw(25);
                            font-size: spvw(24);
                        }
                    }
    
                    &_txt {
                        margin-bottom: 1.45em;
                        font-size: 14px;
    
                        @include mq(sp) {
                            margin-bottom: spvw(25);
                            font-size:spvw(28);
                        }
                    }
    
                    &_lnk {
                        margin-bottom: 1.45em;
                        font-size: 14px;
    
                        @include mq(sp) {
                            margin-bottom: spvw(25);
                            font-size:spvw(28);
                        }
                    }
    
                    &_ico {
                        display: inline-block;
                        font-size: 12px;
                        border-radius: 1em;
                        border: solid 1px #c8b9f9;
                        color: #5330c1;
                        background-color: #FFFFFF;
                        padding: 0 0.8em;
                        letter-spacing: -.05em;
    
                        @include mq(sp) {
                            font-size:spvw(20);
                        }
                    }
                }
                &:hover {
                    text-decoration: none;
                    dl {
                        dt {
                            figure {
                                img {
                                    transform: scale(1.05);
                                    @include mq(sp) {
                                        transform: scale(1);
                                    }
                                }
                            }
                        }
                    }
                    p.inctxt_ccarousel_ttl {
                        // color: $purple;
                    }
                }
                &[target="_blank"] {
                    > dl {
                        > dd {
                            p {
                                &.inctxt_ccarousel_lnk {
                                    position: relative;
                                    display: inline-block;
                                    &:after {
                                        content: "";
                                        position: absolute;
                                        width: 1.3em;
                                        height: 1.6em;
                                        right: -1.5em;
                                        bottom: -0.05em;
                                        display: inline-block;
                                        background: transparent url(/common/img/ico/ico_blank.svg) right top / contain no-repeat;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
	}
}


body.ie {
    .ccarousel_intro {
        .inc_ccarousel_ul {
            .inc_ccarousel_li {
                > a {
                    &[target="_blank"] {
                        > dl {
                            > dd {
                                p {
                                    &.inctxt_ccarousel_lnk {
                                        &:after {
                                            width: 1.2em;
                                            height: 1.2em;
                                            bottom: 0.4em;
                                            background: transparent url(/common/img/ico/ico_blank.png) center center / contain no-repeat;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}