@charset "utf-8";

// -------------------- [ color ]
$black: rgba(0,0,0,1);
$white: rgba(255,255,255,1);
$defcolor: rgba(44,42,52,1);
$purple: rgba(83,48,193,1);
$purple2: rgba(137,115,206,1);
$purple3: rgba(121,97,195,1);
$purple4: rgba(92,68,171,1);
$purple5: rgba(155,133,222,1);
$purple6: rgba(200,185,249,1);
$purple7: rgba(219,213,239,1);
$purple8: rgba(245,242,255,1);
$glay: rgba(246,245,250,1);
$glay2: rgba(187,187,187,1);
$glay3: rgba(170,170,170,1);
$glay4: rgba(221,221,221,1);
$glay5: rgba(119,119,119,1);
$glay6: rgba(237,237,237,1);
$glay7: rgba(235,235,235,1);
$orange: rgba(255,93,76,1);