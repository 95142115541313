@charset "UTF-8";

.common_inc {
	margin: 25px 0 50px;
	@include mq(sp) {
		width: 100%;
		margin: spvw(35) auto spvw(70);
	}
	.common_inc_main {
		padding: 65px 0 40px;
		@include mq(sp) {
			padding: spvw(98) 0 spvw(61);
			width: 92%;
			margin: 0 auto;
		}
		.contents {
			margin-top: 0;
			margin-bottom: 0;
		}
	}
}



@import './cases';
@import './history';
@import './newinfo';
@import './pickup';
@import './recommend';
@import './ccarousel';
@import './related';
@import './searchdtl';
@import './acrobat';




