@charset "utf-8";


@keyframes body_load {
    0% { opacity: 1; }
    40% { opacity: 1; pointer-events: all; }
    99.9% { z-index: 10000;}
    100% { opacity: 0; pointer-events: none; z-index: -1;}
}
body {
    &::before {
        content: "";
        display: block;
        position: fixed;
        width: 100vw;
        height: 100vh;
        left: 0;
        top: 0;
        z-index: 10000;
        background-color: $white;
        opacity: 1;
        pointer-events: all;
        transition: opacity 1s .2s;
        animation: body_load 2.5s forwards;
    }
    // &.loaded {
    //     &::before {
    //         opacity: 0;
    //         pointer-events: none;
    //     }
    // }
}

main {
    padding-top: 109px;

	@include mq(md) {
    }

	@include mq(sp) {
        padding-top: 14vw;
    }
}
body.ie {
    main {
        padding-top: 1px;
        margin-top: 108px;
    }
    select {
        &::-ms-value {
            background-color: transparent;
            color: $defcolor;
        }
      
        option {
            &:hover {
                background-color: $purple;
                color: $white;
            }
            &:checked {
                background-color: transparent;
                color: $defcolor;
            }
        }
    }
}
body#top.ie {
    main {
        display: block;
        padding-top: 1px;
        margin-top: 108px;
    }
}

// aタグにアイコン追加
a {
    &:hover {
        color: $purple;
    }
    &.pdf {
        position: relative;
        &:after {
            content: "";
            position: absolute;
            width: 1.9em;
            height: 1.5em;
            right: -1.4em;
            bottom: -0.2em;
            display: inline-block;
            background: transparent url(/common/img/ico/ico_pdf.svg) right top / contain no-repeat;
        }
    }
    &.blank {
        position: relative;
        &:after {
            content: "";
            position: absolute;
            width: 1.1em;
            height: 1.4em;
            right: -1.3em;
            bottom: -0.2em;
            display: inline-block;
            background: transparent url(/common/img/ico/ico_blank.svg) right top / contain no-repeat;
        }
    }
}
body.ie {
    a {
        &.pdf {
            &:after {
                width: 1.3em;
                height: 1.3em;
                bottom: 0.2em;
                background: transparent url(/common/img/ico/ico_pdf.png) center center / contain no-repeat;
            }
        }
        &.blank {
            &:after {
                width: 1.3em;
                height: 1.3em;
                bottom: 0.4em;
                background: transparent url(/common/img/ico/ico_blank.png) center center / contain no-repeat;
            }
        }
    }
}



// マップアイコン
.btn_map01 {
    display: inline-block;
    margin-left: .8em;
    > a {
        padding: .6em .4em .4em 1.4em;
        position: relative;
        font-size: 14px;
        @include mq(sp) {
            font-size: spvw(20);
        }
        background: transparent url(/common/img/ico/ico_map.svg) left top .3em / 1.15em no-repeat;
        &:after {
            position: absolute;
            content: "";
            width: 0.5em;
            height: 0.5em;
            border-top: 1px solid $purple;
            border-right: 1px solid $purple;
            transform: rotate(45deg);
            top: 0.8em;
            right: -0.4em;
            transition: right .3s;
        }
        &:hover {
            &:after {
                right: -0.65em;
            }
        }
        > span {
            color: $purple;
            font-size: inherit;
            font-weight: bold;
            letter-spacing: .04em;
        }
    }
}



// タイトル部分
.cec_h1ttl_02 {
    width: 100%;
    height: 220px;
    background: transparent url(../img/bg_h1_pc.jpg) center center / cover no-repeat;
    display: flex;
    align-items: center;
    justify-content: center;
    > div {
        position: relative;
        background-color: rgba(255,255,255,.9);
        text-align: center;
        padding: 8px 40px 18px;
        > p {
            color: $purple;
            font-size: 30px;
            font-weight: 700;
            > span {
                color: $defcolor;
                font-size: 20px;
                display: inline-block;
                margin-bottom: .1em;
                font-family: 'Roboto Condensed';
                font-weight: bold;
            }
        }
        &::before {
            @extend .ttl_deco_before;
        }
        &::after {
            @extend .ttl_deco_after;
        }
    }
	@include mq(md) {
    }

	@include mq(sp) {
        height: spvw(360);
        background: transparent url(../img/bg_h1_sp.jpg) center center / cover no-repeat;
        > div {
            padding: spvw(20) spvw(40) spvw(30);
            box-sizing: border-box;
            max-width: 90%;
            > p {
                font-size: spvw(36);
                > span {
                    font-size: spvw(30);
                }
            }
        }
    }
}

body.ie {
    .cec_h1ttl_02 {
        padding-top: 109px;
    }
}


// パンくず
.pankuzu_box {
    @include w_center(1200);
    padding: 18px 20px;
    box-sizing: border-box;
    nav {
        &.pankuzu {
            ul {
                display: flex;
                flex-wrap: wrap;
                > li {
                    margin-right: .4em;
                    position: relative;
                    padding-left: 1.6em;
                    font-size: 12px;
                    line-height: 1.8em;
                    &::before {
                        position: absolute;
                        content: "＞";
                        left: 0;
                        top: 0;
                        color: $glay3;
                    }
                    &:first-child {
                        padding-left: 0;
                        &::before {
                            display: none;
                        }
                    }
                    > a {
                        color: $glay3;
                    }
                }
            }
        }
    }
	@include mq(sp) {
        padding: spvw(32) 0;
        margin: 0 auto;
        width: 92%;
        nav {
            &.pankuzu {
                ul {
                    > li {
                        font-size: spvw(20);
                    }
                }
            }
        }
    }
}