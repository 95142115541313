@charset "UTF-8";


/* ------------------------------- */
//
// 関連する製品・サービスを探す
//
/* ------------------------------- */
ul#search_dtl_ul {
    display: flex;
    width: 100%;
    margin: 100px auto 50px;
    position: relative;
    @include mq(sp) {
        margin-top: spvw(100);
    }
    &:after {
        position: absolute;
        content: "";
        width: 25%;
        height: 2px;
        bottom: 0;
        left: 0;
        background-color: $purple;
        transition: left .3s;
    }
    &.l0:after { left: 0; }
    &.l1:after { left: 25%; }
    &.l2:after { left: 50%; }
    &.l3:after { left: 75%; }
    > li {
        width: 25%;
        text-align: center;
        border-bottom: 2px solid #eee;
        color: #aaa;
        font-size: 14px;
        padding: 1.3em 0;
        cursor: pointer;
        @include mq(sp) {
            letter-spacing: -.01em;
            font-size: spvw(28);
        }
        &.act {
            font-weight: bold;
            color: $purple;
            // border-bottom: 2px solid $purple;
        }
    }
}
.search_dtl_tab {
    position: relative;
    overflow: hidden;
    > div {
        position: absolute;
        opacity: 0;
        pointer-events: none;
        transition: opacity .7s;
        &.act {
            opacity: 1;
            pointer-events: all;
        }
        &.search_dtl_tab_key {
        }
        &.search_dtl_tab_solution {
            .search_tabset {
                padding: 0 0 40px;
                margin-bottom: 50px;
                border-bottom: 1px solid #ebebeb;
                h3 {
                    &.search_h3 {
                        font-size: 16px;
                        font-weight: bold;
                        margin: 0 0 20px;
                        @include mq(sp) {
                            font-size: spvw(32);
                        }
                    }
                }
                ul.stab_word {
                    display: flex;
                    justify-content: space-between;
                    flex-wrap: wrap;
                    > li {
                        width: 32%;
                        display: inline-block;
                        margin: .8em 0;
                        @include mq(sp) {
                            width: 100%;
                        }
                        > a {
                            display: inline;
                            font-size: 14px;
                            @include mq(sp) {
                                letter-spacing: -.01em;
                                font-size: spvw(28);
                            }
                        }
                    }
                }
            }
        }
        ul.search_list {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            > li {
                width: 32%;
                display: block;
                position: relative;
                margin-bottom: 20px;
                @include mq(sp) {
                    width: 100%;
                    margin-bottom: spvw(40);
                }
                input {
                    position: absolute;
                    top:0;
                    opacity: 0;
                    &:checked {
                        & + label {
                            background-color: #eee8ff;
                            border: #c8b9f9 1px solid;
                            > p{

                            }
                        }
                    }
                }
                > svg {
                    position: absolute;
                    height: 70%;
                    top: 15%;
                    left: 8%;
                }
                label {
                    display: flex;
                    align-items: center;
                    border: #f2f2f2 1px solid;
                    border-radius: 50px;
                    height: 50px;
                    width: 100%;
                    box-sizing: border-box;
                    cursor: pointer;
                    background-color: #f2f2f2;
                    background-repeat: no-repeat;
                    background-position: left 13% center;
                    background-size: 10%;
                    &[for="label_purpose_01"] { background-image: url(/common/img/ico/ico_svc_inf.svg); }
                    &[for="label_purpose_02"] { background-image: url(/common/img/ico/ico_svc_ssk.svg); }
                    &[for="label_purpose_03"] { background-image: url(/common/img/ico/ico_svc_mssr.svg); }
                    &[for="label_purpose_04"] { background-image: url(/common/img/ico/ico_svc_kt.svg); }
                    &[for="label_purpose_05"] { background-image: url(/common/img/ico/ico_svc_spe.svg); }
                    &[for="label_purpose_06"] { background-image: url(/common/img/ico/ico_svc_hkk.svg); }
                    &[for="label_purpose_07"] { background-image: url(/common/img/ico/ico_svc_bcp.svg); }
                    &[for="label_purpose_08"] { background-image: url(/common/img/ico/ico_svc_eig.svg); }
                    &[for="label_purpose_09"] { background-image: url(/common/img/ico/ico_svc_ict.svg); }
                    &[for="label_purpose_10"] { background-image: url(/common/img/ico/ico_svc_nib.svg); }
                    &[for="label_key_01"] { background-image: url(/common/img/ico/ico_svc_crmsrp.svg); }
                    &[for="label_key_02"] { background-image: url(/common/img/ico/ico_svc_sc.svg); }
                    &[for="label_key_03"] { background-image: url(/common/img/ico/ico_svc_dx.svg); }
                    &[for="label_key_04"] { background-image: url(/common/img/ico/ico_svc_mig.svg); }
                    &[for="label_key_05"] { background-image: url(/common/img/ico/ico_svc_sf.svg); }
                    &[for="label_key_06"] { background-image: url(/common/img/ico/ico_svc_dc.svg); }
                    &[for="label_key_07"] { background-image: url(/common/img/ico/ico_svc_mn.svg); }
                    &[for="label_key_08"] { background-image: url(/common/img/ico/ico_svc_aiiot.svg); }
                    &[for="label_key_09"] { background-image: url(/common/img/ico/ico_svc_cloud.svg); }
                    &[for="label_key_10"] { background-image: url(/common/img/ico/ico_svc_ms.svg); }
                    &[for="label_section_01"] { background-image: url(/common/img/ico/ico_svc_ssb.svg); }
                    &[for="label_section_02"] { background-image: url(/common/img/ico/ico_svc_szg.svg); }
                    &[for="label_section_03"] { background-image: url(/common/img/ico/ico_svc_kkt.svg); }
                    &[for="label_section_04"] { background-image: url(/common/img/ico/ico_svc_egb.svg); }
                    &[for="label_section_05"] { background-image: url(/common/img/ico/ico_svc_btr.svg); }
                    &[for="label_section_06"] { background-image: url(/common/img/ico/ico_svc_irg.svg); }
                    &[for="label_section_07"] { background-image: url(/common/img/ico/ico_svc_kyg.svg); }
                    &:hover {
                        background-color: #eee8ff;
                        border: #c8b9f9 1px solid;
                        color: $purple;
                    }
                    @include mq(sp) {
                        height: spvw(100);
                        border-radius: spvw(100);
                        background-size: 10%;
                    }
                    > p {
                        box-sizing: border-box;
                        margin: 0;
                        padding-left: 7.2em;
                        font-size: 14px;
                        font-weight: bold;
                        @include mq(sp) {
                            font-size: spvw(28);
                        }
                    }
                }
            }
        }
    }
}