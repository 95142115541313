@charset "UTF-8";

/* ------------------------------- */
//
// PICK UP
//
/* ------------------------------- */
.common_inc.inc_pickup {
	position: relative;
	&:before {
		content: "";
		position: absolute;
		left: 0;
		top: 83px;
		height: 100%;
		width: calc(50% + 380px);
		background: url(/common/img/inc/bg_pickup.png) top right / cover no-repeat;
		z-index: -1;

		@include mq(sp) {
			background: url(/common/img/inc/bg_pickup_sp.png) top left / cover no-repeat;
			width: pcnt(660, 750);
			top: 17.5vw;
		}
	}

	.common_inc_main {
		position: relative;
		// padding: 60px 0 40px;
		@include mq(md2) {
			overflow-x: clip;
		}
		@include mq(sp) {
			height: auto;
			width: 100%;
		}
		.contents {
			margin-top: 0;
			width: 1400px;
			max-width: 1400px;
			@include mq(md2) {
				position: absolute;
				left: calc(50vw - 700px);
			}
			@include mq(sp) {
				width: auto;
				height: auto;
			}
		}
		.slick-slide {
			@include mq(sp) {
				margin: 0 3.33vw;
			}
		}
		.slick-track {
			margin: 0;
		}
		.slick-dotted.slick-slider {
			@include mq(md2) {
				margin-bottom: 0px;
			}
		}
		.slick-dots {
			bottom: -40px;
			li {
				width: 40px;
				height: auto;
				@include mq(sp) {
					width: 8vw;
				}
				button {
					width: 40px;
					height: 3px;
					background-color: #ccc;
					padding: 0;
					@include mq(sp) {
						width: 8vw;
					}
					&:before {
						width: 0;
						height: 3px;
						background-color: #8973ce;
						content: '';
						border-radius: 3px;
						text-align: center;
						opacity: 1;
					}
				}
				&.slick-active {
					button {
						&:before {
							opacity: 1;
							animation: dotsanime 4s linear forwards;
						}
					}
				}

			}
		}
	}
}
.inc_pickup_ul {
	.inc_pickup_li {
		@include mq(sp) {
			margin: 0;
		}
		> a {
			color: $defcolor;
			> dl {
				width: 260px;
				margin: 0 auto;
				@include mq(sp) {
					width: auto;
				}
				> dt {
					> figure {
						width: 100%;
						height: 260px;
						display: block;
						position: relative;
						overflow: hidden;
						@include mq(sp) {
							position: static;
							width: auto;
							height: auto;
						}
						> img {
							position: absolute;
							transform: scale(1);
							transition: transform .3s;
							@include mq(sp) {
								position: static;
							}
						}
					}
				}
				> dd {
					width: 100%;
					box-sizing: border-box;
					padding: 0;
					@include mq(sp) {
						width: auto;
						padding: 0;
					}
					.inctxt_pick {
						&_ttl {
							font-size: 14px;
							font-weight: bold;
							margin: 1em 0 .5em;
							line-height: 1.7em;
							@include mq(sp) {
								font-size: spvw(28);
							}
						}
						&_txt {
							font-size: 12px;
							@include mq(sp) {
								font-size: spvw(24);
								margin: 0;
							}
						}
						&_ico {
							display: inline-block;
							> span {
								display: flex;
								justify-content: center;
								align-items: center;
								font-size: 10px;
								width: 60px;
								height: 18px;
								border-radius: 10px;
								@include mq(sp) {
									font-size: spvw(20);
								}
							}
							&.new {
								> span {
									background-color: $purple;
									&:before {
										content: "NEW"; 
										color: $white;
										font-size: inherit;
									}
								}
							}
							&.hot {
								> span {
									background-color: #ff5d4c;
									&:before {
										content: "HOT"; 
										color: $white;
										font-size: inherit;
									}
								}
							}
							}
					}
				}
			}
			&:hover {
				text-decoration: none;
				> dl {
					> dt {
						> figure {
							> img {
								transform: scale(1.05);
								@include mq(sp) {
									transform: scale(1);
								}
							}
						}
					}
					> dd {
						p {
							&.inctxt_pick_ttl {
								// color: $purple;
							}
						}
					}
				}
			}
			&[target="_blank"] {
				> dl {
					> dd {
						p {
							&.inctxt_pick_txt {
								position: relative;
								display: inline-block;
								&:after {
									content: "";
									position: absolute;
									width: 1.3em;
									height: 1.6em;
									right: -1.5em;
									bottom: -0.05em;
									display: inline-block;
									background: transparent url(/common/img/ico/ico_blank.svg) right top / contain no-repeat;
								}
							}
						}
					}
				}
			}
		}
	}
}


body.ie {
	.inc_pickup_ul {
		.inc_pickup_li {
			> a {
				&[target="_blank"] {
					> dl {
						> dd {
							p {
								&.inctxt_pick_txt {
									&:after {
										width: 1.2em;
										height: 1.2em;
										bottom: 0.4em;
										background: transparent url(/common/img/ico/ico_blank.png) center center / contain no-repeat;
									}
								}
							}
						}
					}
				}
			}
		}
	}
}
